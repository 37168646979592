exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sito-js": () => import("./../../../src/pages/sito.js" /* webpackChunkName: "component---src-pages-sito-js" */),
  "component---src-pages-spazio-soci-biblioteca-js": () => import("./../../../src/pages/spazio-soci/biblioteca.js" /* webpackChunkName: "component---src-pages-spazio-soci-biblioteca-js" */),
  "component---src-pages-spazio-soci-home-js": () => import("./../../../src/pages/spazio-soci/home.js" /* webpackChunkName: "component---src-pages-spazio-soci-home-js" */),
  "component---src-pages-spazio-soci-ricette-js": () => import("./../../../src/pages/spazio-soci/ricette.js" /* webpackChunkName: "component---src-pages-spazio-soci-ricette-js" */),
  "component---src-pages-spazio-soci-video-js": () => import("./../../../src/pages/spazio-soci/video.js" /* webpackChunkName: "component---src-pages-spazio-soci-video-js" */)
}

