import React from "react"
import PropTypes from "prop-types"

export function LinkRenderer({ href, children }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

export function flatten(text, child) {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

export function HeadingRenderer({ children, level }) {
  const text = React.Children.toArray(children).reduce(flatten, "")
  const slug = text.toLowerCase().replace(/\W/g, "-")
  return React.createElement(`h${level}`, { id: slug }, children)
}

LinkRenderer.propTypes = {
  href: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

HeadingRenderer.propTypes = {
  href: PropTypes.string.isRequired,
  level: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}
