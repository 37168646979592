import { createGlobalStyle } from "styled-components/macro"

// locally hosted google fonts
// CC-in-JS path is not available in runtime
import Comfortaa from "../../fonts/Comfortaa.woff2"
import McLaren from "../../fonts/McLaren.woff2"

const FontStyles = createGlobalStyle`
@font-face {
  font-family: "McLaren";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(${McLaren}) format('woff2');
} 
@font-face {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(${Comfortaa}) format('woff2');
}
`

export default FontStyles