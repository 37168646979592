import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components/macro"

import { QUERY, COLORS } from "../../constants"

const SocialPush = ({ label, href, target, icon }) => {
  return (
    <Wrapper href={href} target={target}>
      {icon && <Icon src={icon.file.publicURL} alt="" />}
    </Wrapper>
  )
}

const Wrapper = styled.a`
  margin: 0 1rem 0 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${COLORS.white};
  display: grid;
  place-items: center;
  text-decoration: none;
  -webkit-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;
  -moz-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;
  -o-box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;
  box-shadow: inset 5px 5px 10px 0px #ffffff, 5px 5px 10px 0px #5f5f5f;

  @media ${QUERY.small} {
    width: 46px;
    height: 46px;
    margin: 0 0.5rem 0 0;
  }
`
const Icon = styled.img`
  width: 30px;

  @media ${QUERY.small} {
    width: 24px;
  }
`

SocialPush.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default SocialPush
