/* eslint-disable react/no-children-prop */
import React from "react"
import Markdown from "react-markdown"
import styled from "styled-components/macro"
import { useStaticQuery, graphql, Link } from "gatsby"
import { LinkRenderer, HeadingRenderer } from "../../utils/markdownUtils"

import { QUERY, COLORS } from "../../constants"
import SocialPush from "../SocialPush"

const FooterSpazio = () => {
  const data = useStaticQuery(graphql`
    query FooterSpazioQuery {
      strapiFooter {
        footerLinks {
          ... on StrapiComponentSharedLink {
            id
            icon {
              file {
                publicURL
              }
              caption
              alternativeText
              url
            }
            href
          }
          ... on StrapiComponentGlobalContactForm {
            id
            inputLabel_1
            inputLabel_2
            privacyCheckboxNote
            textAreaLabel
          }
          ... on StrapiComponentSharedEdito {
            id
            testo
            titolo
          }
        }
        statuto {
          file {
            publicURL
          }
        }
      }
    }
  `)

  const { footerLinks, statuto } = data.strapiFooter
  const testi = footerLinks.filter(item => item.testo && item)
  const social = footerLinks.filter(item => item.icon && item)

  return (
    <>
      <Wrapper>
        <WrapperNote>
          <WrapperSocial>
            {/*             <SocialPush
              label={social[0].label}
              href={social[0].href}
              target={social[0].target}
              icon={social[0].icon}
              position="fixed"
            /> */}
            <SocialPush
              label={social[3].label}
              href={social[3].href}
              target={social[3].target}
              icon={social[3].icon}
              position="fixed"
            />
            <SocialPush
              label={social[1].label}
              href={social[1].href}
              target={social[1].target}
              icon={social[1].icon}
              position="fixed"
            />
            <SocialPush
              label={social[2].label}
              href={social[2].href}
              target={social[2].target}
              icon={social[2].icon}
              position="fixed"
            />
          </WrapperSocial>
          <Titolo>{testi[0].titolo}</Titolo>
          <Nota
            renderers={{
              heading: HeadingRenderer,
              link: LinkRenderer,
            }}
            children={testi[0].testo}
          />
          <Csen src={social[4].icon.file.publicURL} alt="" />
          <Titolo>{testi[1].titolo}</Titolo>
          <Nota
            renderers={{
              heading: HeadingRenderer,
              link: LinkRenderer,
            }}
            children={testi[1].testo}
          />
        </WrapperNote>
      </Wrapper>
      <WrapperBottom>
        <span>
          Copyright &copy;
          {` ${new Date().getFullYear()} `} A.S.D. YUAN — Tutti i diritti
          riservati
        </span>
        <WrapperLegal>
          <Link to="/privacy-policy">Privacy </Link>
          <Link to="/cookie-policy">Cookie </Link>
          <a href={statuto.file.publicURL} download>
            Statuto
          </a>
        </WrapperLegal>
      </WrapperBottom>
    </>
  )
}

const Wrapper = styled.footer`
  background: ${COLORS.blue};
  padding: 4rem 2rem;
  display: flex;
  position: relative;

  @media ${QUERY.small} {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    padding-top: 4rem;
    font-size: calc(0.9 * var(--baseline-size));
    font-weight: 300;
  }
`

const WrapperBottom = styled.div`
  background: ${COLORS.blue};
  display: flex;
  padding: 2rem;
  font-weight: 300;
  font-size: calc(1 * var(--baseline-size));

  @media ${QUERY.small} {
    flex-direction: column;
    alig-items: center;
    text-align: center;
    font-size: calc(0.9 * var(--baseline-size));
  }
`

const WrapperLegal = styled.span`
  margin-left: auto;
  a {
    margin: 0.5rem 1rem;
    text-decoration: none;
    color: inherit;
  }

  @media ${QUERY.small} {
    margin: 1rem auto;

    a {
      margin: 0.5rem;
    }
  }
`

const Csen = styled.img`
  width: 4rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const WrapperNote = styled.div`
  min-width: 350px;
  max-width: 450px;
  margin-left: 6rem;
  display: flex;
  flex-direction: column;

  @media ${QUERY.small} {
    min-width: 20px;
    max-width: 350px;
    padding: 1rem;
    margin: 0;
  }
`

const WrapperSocial = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;

  @media ${QUERY.small} {
    margin: 3rem auto;
    align-self: center;
  }
`

const Titolo = styled.p`
  font-size: calc(1 * var(--baseline-size));
  font-weight: 300;
  color: ${COLORS.red};
`

const Nota = styled(Markdown)`
  font-size: calc(0.9 * var(--baseline-size));
  font-weight: 300;
`

export default FooterSpazio
