import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components/macro"

import { QUERY } from "../../constants"

const HeaderSpazio = () => {
  const data = useStaticQuery(graphql`
    query Hero3Query {
      strapiHero {
        yuanRosso {
          file {
            publicURL
          }
        }
        logo {
          file {
            publicURL
          }
        }
      }
    }
  `)

  const { logo, yuanRosso } = data.strapiHero

  return (
    <>
      <Wrapper>
        <Link to="/">
          <Logo src={logo.file.publicURL} alt="" />{" "}
        </Link>
        <Title>
          <TitleTopWrapper>
            <RedLogo src={yuanRosso.file.publicURL} alt="" />
            <TitleTop></TitleTop>
          </TitleTopWrapper>
          <TitleBottom>Spazio soci</TitleBottom>
        </Title>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  padding: 4rem 8rem 2rem;

  @media ${QUERY.medium} {
    padding: 4rem 4rem 2rem;
  }
`

const Logo = styled.img`
  width: 12rem;
  height: 12rem;

  @media ${QUERY.medium} {
    width: 8rem;
    height: 8rem;
  }

  @media ${QUERY.small} {
    width: 5rem;
    height: 5rem;
  }

  @media ${QUERY.verySmall} {
    width: 3.3rem;
    height: 3.3rem;
  }
`
const RedLogo = styled.img`
  height: calc(4.3 * var(--baseline-size));
  margin-right: 1.8rem;
  margin-top: 0.9rem;

  @media ${QUERY.medium} {
    height: calc(2.8 * var(--baseline-size));
    margin-right: 0.8rem;
    margin-top: 0.5rem;
  }
  @media ${QUERY.small} {
    margin-right: 0.4rem;
    margin-top: 0.25rem;
    height: calc(2 * var(--baseline-size));
  }
  @media ${QUERY.smaller} {
    height: calc(1.6 * var(--baseline-size));
  }
  @media ${QUERY.smallest} {
    height: calc(1.4 * var(--baseline-size));
  }
  @media ${QUERY.verySmall} {
    height: calc(1.2 * var(--baseline-size));
  }
`

const Title = styled.div`
  margin-top: 0.8rem;
  margin-left: auto;
  font-size: calc(4.8 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    margin-top: 0.5rem;
    font-size: calc(3 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(2 * var(--baseline-size));
  }

  @media ${QUERY.smaller} {
    font-size: calc(1.8 * var(--baseline-size));
  }

  @media ${QUERY.smallest} {
    font-size: calc(1.6 * var(--baseline-size));
  }

  @media ${QUERY.verySmall} {
    font-size: calc(1.4 * var(--baseline-size));
  }
`
const TitleTopWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
`
const TitleTop = styled.div``
const TitleBottom = styled.div`
  text-align: right;
`

export default HeaderSpazio
