import { createGlobalStyle } from "styled-components/macro"

import { COLORS, DARKTHEME, LIGHTTHEME, WEIGHTS } from "../../constants"

const GlobalStyles = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
    font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
	content: none;
}
table {
  border-collapse: collapse;
	border-spacing: 0;
}
/* GLOBAL STYLES */
[data-theme="dark"] {
  /* dark them */ 
  --toggle: ${DARKTHEME.darkThemeToggle};
  --bg: ${DARKTHEME.veryDarkBlueBG};
  --top-bg-pattern: ${DARKTHEME.veryDarkBlueTopBG};
  --card-bg: ${DARKTHEME.darkDesaturatedBlue};
  --text: ${DARKTHEME.desaturatedBlue};
  --text-metrics: ${COLORS.white};
  --hover:  ${DARKTHEME.hoverDark};
  --divider: ${DARKTHEME.hoverDark};
}
[data-theme="light"] {
    /* light theme */ 
    --toggle: ${LIGHTTHEME.lightThemeToggle};
    --bg: ${COLORS.white};
    --top-bg-pattern: ${LIGHTTHEME.veryPaleBlue};
    --card-bg: ${LIGHTTHEME.lightGrayishBlue};
    --text: ${LIGHTTHEME.darkGrayishBlue};
    --text-metrics: ${LIGHTTHEME.veryDarkBlue};
    --hover: ${LIGHTTHEME.hoverLight};
    --divider: ${LIGHTTHEME.darkGrayishBlue};
}
html {
    --font-sans-serif: 'Comfortaa', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui,
      helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
    --font-serif: 'McLaren',
      Iowan Old Style, Apple Garamond, Baskerville, Times New Roman,
      Droid Serif, Times, Source Serif Pro, serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol;

      --swiper-theme-color: hsl(359, 69%, 50%); 
      
    --normal: ${WEIGHTS.normal};
    --medium: ${WEIGHTS.medium};
    --baseline-size: calc(14 / 16 * 1rem);
    /* common colors */ 
    --white: ${COLORS.white};
    --red: ${COLORS.red};
    --black: ${COLORS.black};
    --common-hover: ${COLORS.commonToggleHover}
}
*,
*:before,
*:after {
  box-sizing: border-box;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  font-synthesis: none;
}
#root {
  /*
    Create a stacking context, without a z-index.
    This ensures that all portal content (modals and tooltips) will
    float above the app.
  */
  isolation: isolate;
}
html {
  /*
    Silence the warning about missing Reach Dialog styles
  */
  --reach-dialog: 1;
}
html, body {
  height: 100%;
  background: var(--bg);
  font-family: var(--font-sans-serif);
}

p {
  margin-bottom: 1em;
}



form {
  max-width: 350px;
  position: relative;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  min-width: 15em;
  
  h4 {
    margin: 0.5em 0;
    font-family: black;
    font-size: 1.6em;
  }
  
  span {
    color: hsla(359, 69%, 50%, 1);
    font-size: 0.9em;
  }
  
  p {
    border-top: 1px solid rgba(201,28,28, 0.8);
    border-bottom: 1px solid rgba(201,28,28, 0.8);
    margin-top: 0;
    padding: 1em 0;
  }
  
  label {
    text-transform: uppercase;
    font-size: 0.8em;
    margin-top: 1em;
    margin-bottom: 0.2em;
  }
  
  input {
    height: 2em;
    padding: 0 0.2em;
  }
  
  input,
  textarea {
    outline: none;
    background-color: rgba(255,255,255, 0.8);
    resize: vertical;
    border: 1px solid rgba(255,255,255, 0.6);
    border-radius: 5px;
  }
  
  /* Change the white to any color ;) */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(201,255,255,0.8) inset !important;
  }
  
  .checkbox {
    padding: 0.2rem;
    display: flex;
    
    input {
      margin-top: 0.4rem;
      margin-right: 0.5rem;
    }
  }
  
  .consent {
    font-size: 0.7em;
    
    a {
      color: inherit;
    }
  }
}

label[for="consent"] {
  text-transform: none;
  
  a {
    color: var.$color;
    text-decoration: none;
    border-bottom: 1px solid var.$color-4;
  }
}

button {
  padding: 0.5rem;
  margin-top: 1.2em;
  text-transform: uppercase;
  border: 1px solid hsla(359, 69%, 50%, 1);
  background-color: hsla(359, 69%, 50%, 1);
  border-radius: 5px;
  box-shadow: none;
  display: block;
  text-align: center;
  text-decoration: none;
  color: white;
  
  &:hover {
    background-color: hsla(359, 69%, 50%, 07);
  }
  
  &:focus {
    outline: none;
  }
}

.form-sent {
  background: white;
  color: hsl(0, 6%, 2%);
  max-width: 350px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  white-space: pre-line;
  border-radius: 25px;
}


.swiper-pagination {
  @media (min-width: 1200px) {
    display:none;  
  }  
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
  border: 1px solid hsl(359, 69%, 50%); 
}

.swiper-button-prev {
  color: hsl(359, 69%, 50%);
  &:after {
    color: hsl(359, 69%, 50%); 
    position: absolute;
    top: 50%;
    right: 5px;
    background: white; 
    border-radius: 10px;
    padding:0.5rem;
    @media (max-width: 1200px) {
      display:none;  
    }
  }  
  @media (max-width: 1200px) {
    display:none;  
  }  
}

.swiper-button-next {
  color: hsl(359, 69%, 50%); 
  &:after {
    color: hsl(359, 69%, 50%); 
    position: absolute;
    top: 50%;
    left: 5px;
    background: white; 
    border-radius: 10px;
    padding:0.5rem;
    @media (max-width: 1200px) {
      display:none;  
    }
  } 
  @media (max-width: 1200px) {
    display:none;  
  }
}

.swiper-container {
  width: 90%;
  position: relative;
  background: transparent;
}

.swiper-button-disabled{
  display:none;   
}

.swiper-pagination {
  top: 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  background: hsla(216, 68%, 66%, 0.1);
  border-radius: 30px;
  top: 0;
  left: 1rem;
  padding: 0.4rem;
  video {
    border-radius: 30px;
   }
  @media (max-width: 400px) {
    left: 0; 
  }
}
`

export default GlobalStyles
