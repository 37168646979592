/**
 * Write-only the password as cookie
 */
import React, { useState } from "react"
import { setSessionPassword } from "@mkitio/gatsby-theme-password-protect/src/utils/utils"
import styled from "styled-components/macro"
import FontStyles from "../../../components/FontStyles"
import GlobalStyles from "../../../components/GlobalStyles"

import { QUERY, COLORS } from "../../../constants"
import HeaderSpazio from "../../../components/HeaderSpazio"
import FooterSpazio from "../../../components/FooterSpazio"

const PasswordProtect = () => {
  const [password, setPassword] = useState("")

  const onSubmit = event => {
    event.preventDefault()
    setSessionPassword(password)
     window.location.reload(); // eslint-disable-line
  }

  return (
    <>
      <FontStyles />
      <GlobalStyles />
      <HeaderSpazio />
      <WrapperX>
        <form onSubmit={onSubmit}>
          <label>Inserisci la password</label>
          <input
            name="password"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <button type="submit">entra</button>
        </form>
      </WrapperX>
      <FooterSpazio />
    </>
  )
}

const WrapperX = styled.div`
  display: grid;
  place-items: center;
  background: ${COLORS.blue};
  padding: 2rem;
  @media ${QUERY.small} {
    padding: 4rem 2rem;
  }
`

export default PasswordProtect
