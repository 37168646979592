export const COLORS = {
  white: "hsl(0deg, 0%, 100%)",
  black: "hsl(0, 6%, 2%)",
  red: "hsl(359, 69%, 50%)",
  blue: "hsla(216, 68%, 66%, 0.4)",
  lightBlue: "hsla(216, 68%, 66%, 0.1)",
  commonToggleHover:
    "linear-gradient(to right, hsl(210, 78%, 56%), hsl(146, 68%, 55%))",
}

export const DARKTHEME = {
  darkThemeToggle:
    "linear-gradient(to right, hsl(210, 78%, 56%), hsl(146, 68%, 55%))",
  veryDarkBlueBG: "hsl(230, 17%, 14%)",
  veryDarkBlueTopBG: "hsl(232, 19%, 15%)",
  darkDesaturatedBlue: "hsl(228, 28%, 20%)",
  desaturatedBlue: "hsl(228, 34%, 66%)",
  white: "hsl(0deg, 0%, 100%)",
  hoverDark: "hsl(228, 34%, 26%)",
}

export const LIGHTTHEME = {
  lightThemeToggle: "hsl(230, 22%, 74%)",
  white: "hsl(0deg, 0%, 100%)",
  veryPaleBlue: "hsl(225, 100%, 98%)",
  lightGrayishBlue: "hsl(227, 47%, 96%)",
  darkGrayishBlue: "hsl(228, 12%, 44%)",
  veryDarkBlue: "hsl(230, 17%, 14%)",
  hoverLight: "hsl(227, 37%, 90%)",
}

export const WEIGHTS = {
  normal: 400,
  medium: 700,
}

export const QUERY = {
  larger: `(max-width: 1480px)`,
  large: `(max-width: 1330px)`,
  mediumLarge: `(max-width: 1280px)`,
  medium: `(max-width: 1134px)`,
  mediumSmall: `(max-width: 876px)`,
  small: `(max-width: 720px)`,
  smaller: `(max-width: 500px)`,
  smallest: `(max-width: 432px)`,
  verySmall: `(max-width: 350px)`,
  customQuery: `(max-width: 380px)`,
  custom: `(max-width: 1335px)`,
  hero: `(max-width: 1100px)`,
  hero600: `(max-width: 600px)`,
  hero1042: `(max-width: 1042px)`,
}
